$blue: #001f60;
$white: #ffffff;
$yellow: #f9bc31;
$margin: 20px;
$maxWidth: 1420px;
$maxMobileWidth: 800px;
$maxTabletWidth: 1200;
$borderRadius: 5px;

.car {
  background-image: url(/assets/car-darker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.logo {
  background-image: url(/assets/logo-white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  width: 9em;
}

.text-page {
  margin: auto;
  padding: 1em;
  max-width: $maxWidth;
  line-height: 1.5em;
}
