@use 'app/app.component';
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'ngx-toastr/toastr';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$app-blue: (
  50: #ffffff,
  100: #dde6f3,
  200: #b4c9e4,
  300: #7fa3d1,
  400: #6992c9,
  500: app.$blue,
  600: #4072b4,
  700: #38649d,
  800: #305687,
  900: #284770,
  A100: #ffffff,
  A200: #dde6f3,
  A400: #6992c9,
  A700: #38649d,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ),
);

$app-primary: mat-palette($app-blue);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
// $typography: mat-typography-config(
//   $font-family: 'Poppins, sans-serif',
// );
//$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat-base-typography($typography);
@include angular-material-theme($app-theme);

html body {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #ffffff;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: #ffffff;
  }

  .mdc-text-field,
  .mdc-button {
    border-radius: app.$borderRadius;
  }

  button.mdc-button {
    width: 150px;
    min-height: 50px;
  }

  @media (max-width: app.$maxMobileWidth) {
    .desktop {
      display: none !important;
    }
  }

  @media (min-width: app.$maxMobileWidth) {
    .mobile {
      display: none !important;
    }
  }
}

html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-weight: 100;

  a {
    font-weight: 100;
  }
}

@media (min-width: 1200px) {
  body.mat-typography {
    font-size: 18px;
  }
}